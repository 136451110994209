import React, { useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Link,
  BottomNavigation,
  BottomNavigationAction,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import footerImage from './footer.png';
import MenuIcon from "@mui/icons-material/Menu";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const [navValue, setNavValue] = useState(0); // Set to default 0 for 'Home'
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* AppBar */}
      <Helmet>
        <title>Политика на Приватност | Upsy.mk</title>
        <meta name="description" content="Прочитајте ја нашата политика на приватност и дознајте како ги користиме и заштитуваме вашите податоци на Upsy.mk." />
      </Helmet>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer anchor="left" sx={{ backdropFilter:"blur(10px)" }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="За Нас" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Правила и Услови" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Политика на Приватност" />
          </ListItem>
        </List>
      </Drawer>

      {/* Side Navbar */}
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center', flex: 1 }}>
      <Hidden smDown>
          <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              {/* <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem> */}
            </List>
          </Box>
        </Hidden>

        {/* Main Content */}
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35, paddingBottom: 50 }}>
        <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Политика за Приватност
            </Typography>
            <Typography variant="body1" fontFamily="Montserrat">

  <b>1. Општа политика</b><br />
  Коки Шпед ДООЕЛ, Шишка бр. 5, Кавадарци, (во понатамошниот текст „Upsy.mk“), се обврзува да ја заштити и почитува вашата приватност како контролор на податоци. Целта на оваа политика за приватност е да ги информира поединците за нашите практики на собирање, користење и откривање информации што може да ги обезбедите преку оваа веб-локација или нашите мобилни апликации (iOS и Android) наречени „платформи“.<br />
  Политиката за приватност ја утврдува основата врз која ќе се обработуваат сите податоци собрани или обезбедени од вас. Ве молиме внимателно прочитајте го следново за да ги разберете нашите ставови и практики во врска со вашите лични податоци и како ќе се третираат со нив.<br /><br />

  <b>2. Какви видови информации собираме и колку долго</b><br />
  <b>2.1 Информации што ни ги давате.</b><br />
  Кога ја користите нашата платформа, пополнувате податоци за платформи (како што се формулари за регистрација), влегувате во кој било од нашите натпревари, промоции и анкети, комуницирате преку телефон или е-пошта или други начини на комуникација и известувате за проблеми со нашите платформи, можете да ни обезбедите информации, вклучувајќи информации што ве идентификуваат (лични податоци).<br /><br />

  Информациите што ги давате може да вклучуваат:<br />
  Задолжителни информации потребни за регистрација за услугите што ги нудиме на нашите платформи или за пристап до услугите што ги обезбедуваме, вклучувајќи го вашето име, презиме, адреса на е-пошта, број на мобилен телефон и лозинка. Сите овие полиња се задолжителни. Доколку не се обезбедат потребните информации, Upsy.mk нема да може да ги обезбедува услугите што се нудат на нашите платформи. Следствено, нема да можете да се регистрирате со корисничка сметка на нашите платформи или да ги користите сите карактеристики на платформата.<br /><br />

  Други информации што може да ги дадете:<br />
  (а) Детали за вашите возила;<br />
  (б) евиденција за сите резервации што сте ги направиле или реклами поврзани со патувања што сте ги доставиле преку нашите платформи;<br />
  (в) Детали за вашите посети на нашите платформи и ресурсите до кои пристапувате;<br />
  (г) Вашите одговори на какви било анкети или прашалници, како што се прегледи за патувања што сте ги споделиле со членовите на нашата заедница. Таквите информации може да се користат за аналитика и разбирање на корисниците;<br />
  (д) Информации што се бараат од вас кога известувате за проблеми на нашите платформи или услуги, како што е целта на вашето барање за поддршка;<br />
  (ѓ) Податоци за локацијата откако сте дале согласност за собирање и обработка на овие податоци;<br /><br />

  <b>2.2. Податоците што ги собираме автоматски</b><br />
  (а) Ако се регистрирате преку вашата е-пошта, Upsy.mk ќе пристапи до одредени лични податоци (на пример, име, презиме, слика, е-пошта итн.) од вашиот профил во согласност со условите на тие платформи за социјални медиуми.<br />
  (б) Во врска со секоја ваша посета на нашите платформи, можеме, во согласност со важечките закони и доколку е потребно со ваша согласност, да собираме информации за уредите што ги користите и мрежите на кои сте поврзани кога ги користите нашите услуги. Ова може да ги вклучува следните податоци: вашата IP адреса, податоци за најава, тип и верзија на прелистувачот, типови и верзии на приклучоци за прелистувач, оперативен систем и платформа, идентификатор за рекламирање, податоци за вашата посета, вклучувајќи го протокот на кликнување на URL-то до, преку и од нашата платформа, производите што сте ги прегледувале или пребарувале, времињата на одговор на страницата, грешките во преземањето, должината на посетите на одредени страници, интеракцијата со страницата и кој било телефонски број што се користи за повикување на нашата служба за корисници. Ги собираме овие податоци користејќи различни технологии.<br /><br />

  (в) Исто така, собираме збирни податоци за вашата активност на нашата платформа (на пр., број на понудени возења, локации за возења, неодамнешни возења итн.). Таквите информации може да бидат прикажани во вашиот јавен профил на нашата платформа.<br /><br />

  <b>2.3. Складирање на податоци</b><br />
  Освен за категориите на лични податоци наведени во деловите 2.3(а) и 2.3(б), вашите лични податоци ќе се чуваат за времетраењето на нашиот однос со вас и нема да се користат понатаму:<br />
  - Податоците за објавените патувања се чуваат една од кој е објавен датумот на патување.<br /><br />

  (а) Следниве категории на лични податоци може да се чуваат за различни периоди: Сите содржини генерирани од корисниците (на пример, коментари и прегледи) стануваат анонимни, но остануваат достапни на нашите платформи во анонимизирана форма.<br />
  (б) Во случај вашата сметка да биде суспендирана, блокирана или избришана, можеме да ги задржиме вашите податоци внатрешно во период до 24 месеци.<br /><br />

  <b>3. Кои се примателите на вашите податоци и за какви цели?</b><br />
  Кога ги користите нашите услуги, некои од вашите податоци се споделуваат со членовите на нашите заедници - било во вашиот јавен профил или за време на процесот на резервација (на пр., секогаш даваме телефонски број).<br />
  Ние, исто така, тесно соработуваме со трети страни кои може да бидат приматели на вашите лични податоци, како што се:<br />
  (а) Нашите деловни партнери кои се платформи за социјални медиуми и можат да ви понудат услуги за поврзување, како што се поврзување на податоците од вашиот профил, најавувања на социјалните медиуми од нивните платформи за социјални медиуми до нашите платформи;<br />
  (б) Нашите подизведувачи во технички, услуги за плаќање и проверка на идентитетот, како и даватели на аналитика.<br /><br />

  <b>3.1 Ги споделуваме вашите податоци со овие трети страни само во случаи:</b><br />
  (а) Каде што е потребно да се вклучи трета страна давател на услуги за олеснување или проширување на нашите услуги;<br />
  (б) Како дел од процесот на резервација и за обезбедување на бараните услуги, информациите како што се вашето име, фотографија, број на мобилен телефон и/или адреса на е-пошта може да се прикажат на платформите и/или да се пренесат на патниците (ако сте возач ) или возачи (ако сте патник);<br />
  (в) Ние користиме аналитика и даватели на пребарување за да ги подобриме и оптимизираме нашите платформи;<br />
  (г) Делови од нашите платформи (вклучувајќи ги и возењата што сте ги објавиле) може да се дистрибуираат за прикажување на веб-локациите на нашите деловни партнери преку API или графички контроли. Во такви случаи, некои информации од вашиот јавен профил може да бидат прикажани на овие веб-локации;<br />
  (д) Upsy.mk, исто така, може да ги открие вашите податоци ако тоа го бара законот или со добра волја дека таквиот пристап, складирање или откривање е разумно неопходно за да се одговори на барањата поднесени против Upsy.mk, да се усогласат со правните постапки и да се спроведат какви било согласност со нашите корисници, како што се нашите услови за користење и политиката за приватност, во итни случаи, вклучително и опасности по јавното здравје, смрт или телесно оштетување на лице за време на истрага или за заштита на правата, имотот или личната безбедност на Upsy.mk, неговите членови или други;<br />
  (ѓ) Во случај да продадеме или купиме кој било бизнис или средства, може да ги откриеме вашите лични податоци на потенцијалниот продавач или купувач на таквиот бизнис или средства;<br />
  (е) Доколку Upsy.mk или суштински сите негови средства се стекнати од трето лице, еден од пренесените средства ќе биде личните податоци на нашите членови.<br /><br />

  Сакаме да ве информираме дека ако изберете да ги споделите вашите податоци со нас преку поврзување на услуги од нашите деловни партнери, вклучително и лични податоци, сите правила за приватност и/или известувања од нашите деловни партнери може да важат и за вас, заедно со известувањето за приватност. Ние не го контролираме собирањето и/или обработката на вашите информации на нивните платформи.<br /><br />

  <b>4. Вашите права во врска со личните податоци</b><br />
  Имате право да добиете копија од вашите лични податоци што ги имаме (вашето право на пристап до податоците).<br />
  Можете да побарате бришење на лични податоци или корекција на неточни лични податоци (вашето право на бришење и исправка). Имајте предвид дека ние може да задржиме одредени податоци за вас како што се бара со закон или кога имаме правна основа за тоа (на пример, легитимен интерес за одржување на безбедна и заштитена платформа за други корисници).<br /><br />

  Имате право да се спротивставите на обработката на вашите лични податоци за цели на директен маркетинг или за обработка на вашите лични податоци за други цели врз основа на вашата специфична ситуација (вашето право на приговор за обработка). Ве молиме имајте предвид дека ова право се применува само доколку обработката на вашите лични податоци се заснова на нашиот легитимен интерес.<br /><br />

  Имате право да ја ограничите обработката на вашите лични податоци (вашето право на ограничување на обработката). Ве молиме имајте предвид дека ова право се применува само ако:<br />
  - Ја оспоривте точноста на вашите лични податоци, а ние ја потврдуваме точноста на личните податоци;<br />
  - Го искористивте вашето право на приговор, а ние сè уште размислуваме дали нашата легитимна основа за обработка на вашите лични податоци во овој случај ги надминува вашите интереси, права и слободи;<br />
  - Незаконски ги обработивме вашите лични податоци, но вие се спротивставувате на бришењето на вашите лични податоци или сакате вашите лични податоци да се чуваат за да се утврди, остварува или брани правно барање.<br /><br />

  Имате право да примате и/или да пренесете на друг контролор на податоци подгрупа лични податоци кои се однесуваат на вас и кои сте ни ги дале и кои ги обработуваме за извршување на нашиот договор или врз основа на ваша претходна согласност, во структурирана, вообичаена користен и машински читлив формат (ваше право на преносливост на податоци).<br /><br />

  Исто така, имате право да поднесете жалба до релевантниот орган за заштита на податоците или да побарате правен лек преку судовите доколку мислите дека вашите права се повредени.<br />
  Доколку сакате да ги побарате вашите податоци, ве молиме контактирајте не на info@upsy.mk за понатамошни чекори.<br />
  Можете да ја избришете вашата сметка на Upsy.mk или со испраќање барање преку е-пошта на info@upsy.mk.<br /><br />

  <b>5. Вашата лозинка на Upsy.mk</b><br />
  Избравте лозинка која ви овозможува пристап до одредени делови од нашата платформа, а вие сте обврзани да ја чувате вашата лозинка приватна. Ве молиме не го споделувајте со други. Upsy.mk нема пристап до вашата лозинка. Никогаш нема да ја побараме вашата лозинка!<br /><br />

  <b>6. Промени во нашето известување за приватност</b><br />
  Сите промени што ги правиме во нашата политика за приватност ќе бидат објавени на оваа страница. Кога е соодветно, ќе ве информираме или ќе побараме ваша согласност. Ве молиме редовно проверувајте дали има ажурирања или промени во нашата политика за приватност.<br /><br />

  <b>7. Контакт и други прашања</b><br />
  Ако имате какви било прашања во врска со вашите лични податоци или приватност, ве молиме контактирајте не на е-поштата info@upsy.mk или по пошта на:<br />
  Коки Шпед ДООЕЛ<br />
  Шишка бр. 5<br />
  1430 Кавадарци<br />
  Северна Македонија<br /><br />

  Политиката за приватност последен пат ажурирана на 11.09.2024.<br /><br />
</Typography>


          </Paper>
        </Container>
      </Container>

      {/* Bottom Navigation */}
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            {/* <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} /> */}
          </BottomNavigation>
        </Box>
      </Hidden>

      {/* Footer */}
      <Hidden smDown>
      <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 'auto', // Adjust height to fit content, not fixed height
    mt: 'auto', // Pushes the footer down if there is content above it
    padding: 0, // Ensure no padding
    margin: 0, // Ensure no margin
    display: 'flex', // Flexbox to help align the content
    justifyContent: 'center' // Centers content in the middle horizontally
  }}
>
  <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'relative', bottom: 0 }} />
  <Box
    sx={{
      position: 'absolute',
      bottom: '20px',
      left: '10px',
      display: 'flex',
      gap: '20px',
    }}
  >
    <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>За нас</Link>
    <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Правила и Услови</Link>
    <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Политика на Приватност</Link>
    <Link href="/contact" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Контакт</Link>
  </Box>
</Box>
      </Hidden>
    </div>
  );
}

export default PrivacyPolicy;
